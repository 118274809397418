import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { convert_int_to_ext_symbol, getColorFromScore } from 'src/app/core/helpers';
import { LoginService } from 'src/app/core/login.service';
import { HighestScoreData } from 'src/app/core/pillars.service';

@Component({
  selector: 'app-most-analyzed-companies',
  templateUrl: './most-analyzed-companies.component.html',
  styleUrls: ['./most-analyzed-companies.component.scss']
})
export class MostAnalyzedCompaniesComponent {
  @Input() limit: number = 0
  highestRanking: Array<HighestScoreData> = []
  displayedColumns = ['symbol', 'name', 'action']
  dataSource: MatTableDataSource<HighestScoreData> = new MatTableDataSource()
  isLoading: boolean = false
  isLoggedIn: boolean = false
  convert_int_to_ext_symbol = convert_int_to_ext_symbol
  getColorFromScore = getColorFromScore

  constructor(
    private router: Router,
    private loginService: LoginService
  ){}

  ngOnInit(): void {
    this.isLoading = true
    this.loginService.isLoggedIn.subscribe(isLoggedIn => {
      console.log('isLoggedChanged: ', isLoggedIn)
      this.isLoggedIn = isLoggedIn
      this.refreshTable()
    })
    this.refreshTable()
  }

  refreshTable(): void {
    this.isLoading = true
    this.highestRanking = [
      {
        symbol: "AAPL",
        company_name: "Apple Inc.",
        score: 100,
        company_industry: "Technology",
        company_sector: "Consumer Electronics",
      },
      {
        symbol: "GOOGL",
        company_name: "Alphabet Inc.",
        score: 100,
        company_industry: "Technology",
        company_sector: "Internet Content & Information",
      },
      {
        symbol: "MSFT",
        company_name: "Microsoft Corporation",
        score: 100,
        company_industry: "Technology",
        company_sector: "Software—Infrastructure",
      },
      {
        symbol: "AMZN",
        company_name: "Amazon.com Inc.",
        score: 100,
        company_industry: "Consumer Cyclical",
        company_sector: "Internet Retail",
      },
      {
        symbol: "TSLA",
        company_name: "Tesla Inc.",
        score: 100,
        company_industry: "Consumer Cyclical",
        company_sector: "Auto Manufacturers",
      },
      {
        symbol: "FB",
        company_name: "Facebook Inc.",
        score: 100,
        company_industry: "Communication Services",
        company_sector: "Internet Content & Information",
      },
      {
        symbol: "NVDA",
        company_name: "NVIDIA Corporation",
        score: 100,
        company_industry: "Technology",
        company_sector: "Semiconductors",
      },
      {
        symbol: "NFLX",
        company_name: "Netflix Inc.",
        score: 100,
        company_industry: "Communication Services",
        company_sector: "Entertainment",
      },
      {
        symbol: "INTC",
        company_name: "Intel Corporation",
        score: 100,
        company_industry: "Technology",
        company_sector: "Semiconductors",
      },
      {
        symbol: "AMD",
        company_name: "Advanced Micro Devices Inc.",
        score: 100,
        company_industry: "Technology",
        company_sector: "Semiconductors",
      },
      {
        symbol: "ADBE",
        company_name: "Adobe Inc.",
        score: 100,
        company_industry: "Technology",
        company_sector: "Software—Application",
      },
      {
        symbol: "PYPL",
        company_name: "PayPal Holdings Inc.",
        score: 100,
        company_industry: "Financial Services",
        company_sector: "Credit Services",
      },
      {
        symbol: "CSCO",
        company_name: "Cisco Systems Inc.",
        score: 100,
        company_industry: "Technology",
        company_sector: "Communication Equipment",
      },
      {
        symbol: "QCOM",
        company_name: "QUALCOMM Inc.",
        score: 100,
        company_industry: "Technology",
        company_sector: "Semiconductors",
      },
      {
        symbol: "IBM",
        company_name: "International Business Machines Corporation",
        score: 100,
        company_industry: "Technology",
        company_sector: "Information Technology Services",
      },
      {
        symbol: "ORCL",
        company_name: "Oracle Corporation",
        score: 100,
        company_industry: "Technology",
        company_sector: "Software—Infrastructure",
      }]
    this.highestRanking = this.limit > 0 ? this.highestRanking.slice(0, this.limit) : this.highestRanking
    this.dataSource = new MatTableDataSource<HighestScoreData>(this.highestRanking)
    this.isLoading = false

    // this.pillarsService.getHighestScoreRanking().subscribe(highestRanking => {
    //   this.highestRanking = highestRanking.filter((val, i, arr) => {
    //     return arr.findIndex(x => x.symbol == val.symbol) == i ? true : false
    //   }).map((val, index) => {
    //     if(!this.isLoggedIn && (/*index < 5 ||*/ index > 18)){
    //       val.score = 100
    //       val.symbol = "FAKE"
    //       val.company_name = "FAKE Industries"
    //       return {...val, 'blur': true}
    //     } else {
    //       return {...val, 'blur': false}
    //     }
    //   })
    //   this.highestRanking = this.limit > 0 ? this.highestRanking.slice(0, this.limit) : this.highestRanking
    //   this.dataSource = new MatTableDataSource<HighestScoreData & {blur: boolean}>(this.highestRanking)
    //   this.isLoading = false
    // })
  }

  goToCompanyPage(symbol: string): void {
    if(symbol != "FAKE"){
      this.router.navigate(['company', convert_int_to_ext_symbol(symbol)])
    }
  }
}
