export const environment = {
  ntVersion: "1.52.1",
  production: true,
  dataServiceUrl: "https://wlvbxwescf.execute-api.eu-west-3.amazonaws.com",
  sannewsEndpoint: "https://x6cdsrgqg9.execute-api.eu-west-3.amazonaws.com",
  userEndpoint: "https://mb6yokqjae.execute-api.eu-west-3.amazonaws.com",

  dataServiceStaticUrl: "https://dqbtgwgyj4x4h.cloudfront.net",
  
  firebaseConfig:  {
    apiKey: "AIzaSyBUDcBMwNvDcFNgRt41cKBzoGBS3LtM-i0",
    authDomain: "neotradr.firebaseapp.com",
    projectId: "neotradr",
    storageBucket: "neotradr.appspot.com",
    messagingSenderId: "1072181277459",
    appId: "1:1072181277459:web:fab87739001893c71b60e6",
    measurementId: "G-PG7J8CS2MX"
  },
  stripePubKey: "pk_live_51JRewlK3tTZOwvJrVI0YeOmbD4X6KOy2jqyCurJqXOQo6uAfCrmTiR4n5MuRarY2XrZTRRYJzkHTlTk6zq5Jq1FL00p93M7Dys",
  neotradr_experimental: {
    enable_subscription: true,
  },
  // premiumProduct: "prod_OwAkdqYE9TXVDp"
  premiumProduct: "prod_PR0CwlnyMvwkM0",

  ibkrReferralLink: "https://ibkr.com/referral/romaincorentin913",
  tradingViewReferralLink: "https://www.tradingview.com/?aff_id=140817"
};
