import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { catchError, map, Observable, ObservableInput, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as firebase from 'firebase/compat/app'
import { LoginService } from './login.service';
import { getLocalStorageItem, setLocalStorageItem } from './local-storage-manager';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface NeoTradrUser extends firebase.default.User {
  picture?: string,
  name?: string,
  email: string,
  neotradr_receive_weekly_report?: boolean,
  neotradr_receive_daily_report?: boolean
}

export interface FavoriteDetail {
  symbol: string
  company_name: string,
  stock_price: Array<{
    value: number,
    data_date: Date,
    timestamp?: Date,
  }>,
  score: number
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userEndpoint: string = environment.userEndpoint
  ntUser: NeoTradrUser | null = null

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private loginService: LoginService) { }

  errorHandlerAny(err: any, caught: Observable<any>): ObservableInput<any> {
    console.error('UserService Any error:', err, caught)
    if(err.statusCode == 401 || err.status == 401){
      this.loginService.getIdToken(true)
      this.snackBar.open(
        'It looks like your token expires, please retry in few seconds. If the problem persists try to logout and login again.',
        'Ok', {duration: 10000})
    }
    return of(null)
  }

  getUser(idToken: string, forceRefresh: boolean = false): Observable<NeoTradrUser> {
    if(forceRefresh){
      console.log('Forcing user refresh')
      setLocalStorageItem('neotradr_user', null, 0)
    }
    const cacheData = getLocalStorageItem(`neotradr_user`)
    if(cacheData){
      return of(cacheData)
    } else {
      return this.http.get(`${this.userEndpoint}/user`, {
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      }).pipe(
        map(res => (<any>res)['body']['result']),
        tap(x => setLocalStorageItem(`neotradr_user`, x, 60*30)),
        catchError(this.errorHandlerAny)
      )
    }
  }

  saveSettings(idToken: string, settings: object){
    return this.http.post(`${this.userEndpoint}/user`, {
      settings: settings
    }, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    })
  }

  setFavoriteStatus(idToken: string, symbol: string, status: boolean): Observable<boolean|null>{
    return this.http.post<{
      statusCode: number,
      body: {
        result: boolean|null,
        error?: string
      }
    }>(`${this.userEndpoint}/user/favorite`, {
      favorite: [{
        symbol: symbol,
        favorite_status: status
      }]
    }, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }).pipe(
      tap(res => {
        if(res.body.error){
          this.snackBar.open(res.body.error, "Ok", {duration: 7000})
        }
      }),
      map(res => res.body.result),
      catchError((error, observable) => this.errorHandlerAny(error, observable))
      // catchError(this.errorHandlerAny)
      )
  }

  getFavoriteList(idToken: string): Observable<Array<string>|null> {
    return this.http.get<{
      statusCode: number,
      body: {
        result: Array<string>|null,
        error?: string
      }
    }>(`${this.userEndpoint}/user/favorite`, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }).pipe(
      tap(res => {
        console.log('get favorite result: ', res)
        if(res.body.error){
          this.snackBar.open(res.body.error, "Ok", {duration: 7000})
        }
      }),
      map(res => res.body.result),
      catchError((error, observable) => this.errorHandlerAny(error, observable))
    )
  }

  getFavoriteDetailsList(idToken: string): Observable<Array<FavoriteDetail>|null> {
    return this.http.get<{
      statusCode: number,
      body: {
        result: Array<FavoriteDetail>|null,
        error?: string
      }
    }>(`${this.userEndpoint}/user/favorite_details`, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }).pipe(
      tap(res => {
        console.log('get favorite details result: ', res)
        if(res.body.error){
          this.snackBar.open(res.body.error, "Ok", {duration: 7000})
        }
      }),
      map(res => res.body.result),
      catchError((error, observable) => this.errorHandlerAny(error, observable))
    )
  }

  suggestCompany(idToken: string, company_name: string): Observable<any> {
    return this.http.post(`${this.userEndpoint}/suggest_new_company`, {
      company_name: company_name
    }, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    })
  }

  setPriceTarget(idToken: string, symbol: string, price_target: number): Observable<any> {
    return this.http.post(`${this.userEndpoint}/price_targets`, {
      price_target: [{
        symbol: symbol,
        price_target: price_target
      }]
    }, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    })
  }

  getPriceTargets(idToken: string): Observable<Array<{
    'symbol': string,
    'price_target': number,
    'timestamp': Date
  }>> {
    return this.http.get<{
      'statusCode': 200,
      'body': {
        'result': Array<{'symbol': string,
        'price_target': number,
          'timestamp': Date
        }>,
      }
  }>(`${this.userEndpoint}/price_targets`, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }).pipe(
      tap(res => console.log('get price targets result: ', res)),
      map(res => res.body.result),
    )
  }
}

