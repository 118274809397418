

<div *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isLoading" class="row">
    <div 
    *ngFor="let article of articles"
    class="mx-auto my-2 col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-4"
    [ngClass]="full_page ? 'col-xl-6 col-lg-5 col-md-5' : 'col-xl-12 col-lg-12 col-md-12'">
        <mat-card>
            <mat-card-header>
                <mat-card-title-group>
                    <mat-card-title>
                        <a href="{{article.url}}" target="_blank">
                            {{article.title}}
                        </a>
                    </mat-card-title>
                    <mat-card-subtitle>
                        {{article.published_at}}
                    </mat-card-subtitle>

                </mat-card-title-group>
            </mat-card-header>
            <mat-card-content>
                <p>
                    {{article.content}}<a href="{{article.url}}" target="_blank">[More]</a>
                </p>

                <a *ngIf="article.img" href="{{article.url}}" target="_blank">
                    <img src="{{article.img}}" class="card-img" alt="Image describing the news" loading="lazy">
                </a>

                <p>Source: {{article.source}}</p>
                <a href="{{article.url}}" target="_blank">Article link</a>
            </mat-card-content>
        </mat-card>
    </div>
</div>



<!-- <div class="row">
    <div *ngFor="let article of articles" class=" mx-auto my-2 col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-4">
        <div class="card bg-dark text-white">
            <div class="card-body pl-1 pr-1 py-3">
                <i *ngIf="article.langage == 'fr'" class="france flag" style="position:absolute; top:25px; left:2px;"></i>
                <a href="{{article.url}}" target="_blank" class="text-white">
                    <h4 class="card-title">
                        {{article.title}}
                    </h4>
                </a>
                <div class="card-text">
                    <div>
                        <strong>{{article.published_at}}</strong>
                    </div>
                    <div>
                        <p>
                            {{article.content}}<a href="{{article.url}}" target="_blank">[More]</a>
                        </p>
                    </div>
                    <a *ngIf="article.img" href="{{article.url}}" target="_blank">
                        <img src="{{article.img}}" class="card-img" alt="Image description" loading="lazy">
                    </a>
                    <p>Source: {{article.source}}</p>
                    <a href="{{article.url}}" target="_blank">Article link</a>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="row">
    <button mat-raised-button class="mx-auto col-4 text-center" color="accent" (click)="fetchNewsArticles()" [disabled]="isFetching">
        <span *ngIf="!isFetching">Load more</span>
        <span><mat-spinner diameter="40" *ngIf="isFetching"></mat-spinner></span>
    </button>
</div>
