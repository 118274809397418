<div>
    <table *ngIf="!isLoading" mat-table [dataSource]="dataSource" class="ml-0 pl-0">
        <ng-container matColumnDef="symbol">
            <th mat-header-cell *matHeaderCellDef> Symbol</th>
            <td mat-cell *matCellDef="let element" [class.blur]="element.blur">
                <!-- <app-login-required [isLoggedIn]="!element.blur" tooltipText="Login to view all data"> -->
                    {{element.symbol}}
                <!-- </app-login-required> -->
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name</th>
            <td mat-cell *matCellDef="let element" [class.blur]="element.blur">
                <!-- <app-login-required [isLoggedIn]="!element.blur" tooltipText="Login to view all data"> -->
                    {{element.company_name}}
                <!-- </app-login-required> -->
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <!-- <app-login-required [isLoggedIn]="!element.blur" tooltipText="Login to view all data"> -->
                    <a mat-icon-button [disabled]="element.blur" [href]="'/company/'+convert_int_to_ext_symbol(element.symbol)" >
                        <mat-icon>keyboard_arrow_right</mat-icon>
                    </a>
                <!-- </app-login-required> -->
            </td>
        </ng-container>
       
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row (click)="goToCompanyPage(row.symbol)" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
