import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Article, ArticlesService } from '../../core/articles.service';

@Component({
  selector: 'app-articles-component',
  templateUrl: './articles-component.component.html',
  styleUrls: ['./articles-component.component.scss']
})
export class ArticlesComponentComponent implements OnInit {
  isLoading: boolean = true
  
  private _searchQuery: string = "";
  @Input() set searchQuery(value: string) {
      this._searchQuery = value;
      this.initNewsList();
  }
  get searchQuery(): string {
    return this._searchQuery;
  }
  @Input() disable_auto_fetch: boolean = false
  @Input() full_page: boolean = true
  
  articles: Article[] = [];
  isFetching = false;

  @HostListener('window:scroll') onScroll(e: Event): void {
    this.detectEndOfPage();
  }

  constructor(private articlesService: ArticlesService) { }

  ngOnInit(): void {
    this.initNewsList();
  }

  getYPosition(e: Event): number {
    return (e.target as Element).scrollTop;
  }

  initNewsList(): void {
    this.isLoading = true
    this.articles = [];
    console.log("SearchQuery: ", this.searchQuery);
    if(this.searchQuery && this.searchQuery.length > 0){
      this.articlesService.getSearchArticlesFrom(this.searchQuery, 0).subscribe(articles => {
        this.articles = articles;
        this.isLoading = false
      });
    } else {
      this.articlesService.getArticlesFrom(0).subscribe(articles => {
        this.articles = articles;
        this.isLoading = false
      });
    }
  }

  fetchNewsArticles(): void {
    console.log("Need to fetch new articles on ",this.searchQuery);
    if(!this.isFetching){
      this.isFetching = true;

      if(this.searchQuery){
        this.articlesService.getSearchArticlesFrom(this.searchQuery, this.articles.length).subscribe(articles => {
          this.articles.push.apply(this.articles, articles);
          this.isFetching = false;
        });
      } else {
        this.articlesService.getArticlesFrom(this.articles.length).subscribe(articles => {
          this.articles.push.apply(this.articles, articles);
          this.isFetching = false;
        });
      }
    } else {
      //console.log("Already fetching articles");
    }
  }

  detectEndOfPage(): void {
    if(!this.disable_auto_fetch){
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        this.fetchNewsArticles();
      }
    }
  }


}
