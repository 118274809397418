import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PillarData, PillarsService } from 'src/app/core/pillars.service';

const getAccentText = (text: string, enabled: boolean): string => {
  if(enabled){
      return `<strong>${text}</strong>`
  } else {
      return text
  }
}

export const getShortTextForPillar = (pillarID: string, isRespected: boolean): string => {
  switch (pillarID) {
      case "1":
          return isRespected ? 'Low PER last 5 years—good valuation.' : 'High PER last 5 years—valuation concern.';
      case "2":
          return isRespected ? 'ROIC growth > 9%—efficient investment.' : 'ROIC growth < 9%—inefficient investment.';
      case "3":
          return isRespected ? 'Revenue up—positive growth.' : 'Revenue down—potential decline.';
      case "4":
          return isRespected ? 'Net income up—profitability improvement.' : 'Net income down—profitability concern.';
      case "5":
          return isRespected ? 'Fewer shares—enhanced shareholder value.' : 'More shares—dilution risk.';
      case "6":
          return isRespected ? 'Low debt/Fcf—strong debt security.' : 'High debt/Fcf—debt security concern.';
      case "7":
          return isRespected ? 'Increased FCF—cash generation strength.' : 'Decreased FCF—cash generation concern.';
      case "8":
          return isRespected ? 'P/FCF < 20—reasonable stock price.' : 'P/FCF > 20—potential overvaluation.';
      case "9":
          return isRespected ? 'Stock <= Graham Number—reasonable pricing.' : 'Stock > Graham Number—possible overpricing.';
      case "10":
          return isRespected ? 'Low LT liabilities/Net Income—debt management.' : 'High LT liabilities/Net Income—debt concern.';
      case "11":
          return isRespected ? 'Dividend growth > 5%—strong management.' : 'Dividend growth < 5%—management concern.';
      case "12":
          return isRespected ? 'Market cap > 10B—substantial market presence.' : 'Market cap < 10B—market standing concern.';
      case "13":
          return isRespected ? 'Stock < DCF price—potential buying opportunity.' : 'Stock > DCF price—potential overvaluation.';
      case "14":
          return isRespected ? 'PER > 3—confidence among investors.' : 'PER < 3—perceived risk.';
      case "15":
          return isRespected ? 'Increasing net income—consistent profitability.' : 'Stagnant net income—profitability concern.';
      case "16":
          return isRespected ? 'P/B < 4—reasonable stock price.' : 'P/B > 4—potentially high stock price.';
      case "17":
          return isRespected ? 'ROIC > 10%—efficient capital use.' : 'ROIC < 10%—capital efficiency concern.';
      case "18":
          return isRespected ? 'ROE > 8%—healthy return for shareholders.' : 'ROE < 8%—lower returns for shareholders.';
      default:
          return 'Invalid pillar ID.';
  }
}

const getTextStrengthAssessmentForPillar = (pillar: PillarData, use_strong_text:boolean=true, short: boolean=false): string|null => {
    switch(pillar.id){
        case "1": {
            if(short){
                return "The company is overpriced based on PER."
            }
            return `PER is ${getAccentText("high", use_strong_text)} compared to historical market average. This can indicate that the company is overpriced compared to its current earnings.`
        }
        case "2": {
            if(short){
                return "The ROIC is not growing sufficiently."
            }
            return `The return on invested capital of the company has ${getAccentText("not grown", use_strong_text)} of at least 9% since 5 years, meaning the company has not improved its cash generation efficiency.`
        }
        case "3": {
            if(short){
                return "The revenue is declining."
            }
            return `The revenue has ${getAccentText("not grown", use_strong_text)} since 5 years ago.`
        }
        case "4": {
            if(short){
                return "The net income is declining."
            }
            return `Net income has ${getAccentText("not grown", use_strong_text)} since 5 years ago. This could impact the PER and mean that the company is not growing as expected.`
        }
        case "5": {
            if(short){
                return "The investors are diluted."
            }
            return `There is ${getAccentText("more shares", use_strong_text)} today than 5 years ago meaning that each shareholder's value has been ${getAccentText("diluted", use_strong_text)}.`
        }
        case "6": {
            if(short){
                return "The company has high debt levels regarding its free cashflow."
            }
            return `The company seems to have a significant amount of ${getAccentText("long term debt", use_strong_text)} compared to the free cashflow its generating. This can be an issue in case of central bank rate hike.`
        }
        case "7": {
            if(short){
                return "The free cashflow is declining."
            }
            return `The free cashflow of this year is ${getAccentText("lower", use_strong_text)} than 5 years ago.`
        }
        case "8": {
            if(short){
                return "Price/Free Cashflow is high."
            }
            return `Price seems ${getAccentText("high", use_strong_text)} compared to the 5 year company free cashflow`
        }
        case "9": {
            if(short){
                return "The company is overvalued regarding its Graham number."
            }
            return `The company current price is greater than the estimated price computed with the Graham number.`
        }
        case "10": {
            if(short){
                return "The company has high debt level regarding its net incomes."
            }
            return `The company seems to have a significant amount of ${getAccentText("long term debt", use_strong_text)} compared to its 5 years net income.`
        }
        case "11": {
            if(short){
                return "The company has no growing dividend."
            }
            return `The company dividend growth over the next 5 years is low or null. A regular dividend growth indicate the company is well managed and rewards its shareholders for the risk taken.`
        }
        case "12": {
            if(short){
                return "The market capitalization is low."
            }
            return `The company market capitalization is low. This can be an issue in difficult market conditions.`
        }
        case "13": {
            if(short){
                return "The company is overvalued regarding our DCF valuation."
            }
            return `The stock price ${getAccentText("seems expensive", use_strong_text)} compared to the discounted cashflow model price estimation.`
        }
        case "14": {
            if(short){
                return "The PER is low."
            }
            return `The PER seems ${getAccentText("very low", use_strong_text)}. This can indicate that the market see no future for this company. Or the company is very cheap to buy.`
        }
        case "15": {
            if(short){
                return "Net income is not steadily growing."
            }
            return `The net income is ${getAccentText("not increasing", use_strong_text)} since at last 3 years. The company may have irregular earnings.`
        }
        case "16": {
            if(short){
                return "The price is high regarding the company book value."
            }
            return `The price of the company ${getAccentText("seems expensive", use_strong_text)} compared the book value (shareholders' equity) of the company.`
        }
        case "17": {
            if(short){
                return "The ROIC is low"
            }
            return `The last return on invested capital is ${getAccentText("low", use_strong_text)} compared to our expected value.`
        }
        case "18": {
            if(short){
                return "The ROE is low"
            }
            return `The last return on equity (shareholder's equity) is ${getAccentText("low", use_strong_text)} compared to our expected value.`
        }
        default: {
            return null
        }
    }
    return "Not implemented"
}


@Component({
  selector: 'app-pillars-textual-strength',
  templateUrl: './pillars-textual-strength.component.html',
  styleUrls: ['./pillars-textual-strength.component.scss']
})
export class PillarsTextualStrengthComponent {
  strengthList: Array<string|null> = []
  percentage: number = 0
  @Input() pillars: Array<PillarData> = []
  @Input() use_strong?: boolean = true;
  @Input() limit?: number = 0;
  @Input() short?: boolean = false;
  @Output() shortChange = new EventEmitter<boolean>();

  constructor(private pillarsService: PillarsService){}

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.computeRisk()
  }

  changeShort(): void {
    this.short = !this.short
    this.shortChange.emit(this.short)
  }

  computeRisk(): void {
    const swot = this.pillarsService.getSWOTAnalysis(this.pillars)
    let strengths = swot.strengths
    this.percentage = (swot.strengths.length / swot.n_internal_pillars)*100
    if(this.limit){
      strengths = strengths.slice(0, this.limit)
    }
    this.strengthList = strengths.map(x => getShortTextForPillar(x.id, !!x.result))
  }
}
