<div style="width: 100%;">
    <!-- <div *ngIf="!this.isLoggedIn" class="">
        <app-login class="" mat-raised-button color="accent">
            <div class="">Login or Sign up to access all data</div>
        </app-login>
    </div> -->
    <table *ngIf="!isLoading" mat-table [dataSource]="dataSource" class="m-0 p-0">
        <ng-container matColumnDef="symbol">
            <th mat-header-cell *matHeaderCellDef>Company</th>
            <td mat-cell *matCellDef="let element" [class.blur]="element.blur">
                <app-login-required [isLoggedIn]="!element.blur" tooltipText="Login to view all data">
                    {{element.company_name}} ({{element.symbol}})
                </app-login-required>
            </td>
        </ng-container>

        <ng-container matColumnDef="score">
            <th mat-header-cell *matHeaderCellDef>VIP Score</th>
            <td mat-cell *matCellDef="let element" [class.blur]="element.blur" [ngStyle]="{'background-color':getColorFromScore(element.score)}">
                <app-login-required [isLoggedIn]="!element.blur" tooltipText="Login to view all data">
                    {{element.score|number:'1.0-0'}}/100
                </app-login-required>
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <app-login-required [isLoggedIn]="!element.blur" tooltipText="Login to view all data">
                    <a mat-icon-button [disabled]="element.blur" [href]="'/company/'+convert_int_to_ext_symbol(element.symbol)" >
                        <mat-icon>keyboard_arrow_right</mat-icon>
                    </a>
                </app-login-required>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row (click)="goToCompanyPage(row.symbol)" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div *ngIf="isLoading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        <h3>Data are loading...</h3>
    </div>
</div>