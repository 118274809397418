import { getTechnicalPillarsFunctions, TechnicalDataForPillars } from "../components/technical-pillars-list/technical_pillars";
import { arrayRange, leastSquares1d } from "../core/helpers";
import { PillarData } from "../core/pillars.service";

export interface MomentumDataForPillars {
    /**
     * Contains all the data needed to compute any momentum pillars
     */
    priceTechnicalData: TechnicalDataForPillars, 
    volumeTechnicalData: TechnicalDataForPillars,
    priceToVolumeData: Array<number>,
    companyIndustry: string,
}

export const computeMomentumPillar1 = (momentumData: MomentumDataForPillars): PillarData => {
    let warnings: string[] = []
    const momentum_industries = [
      "Technology", "Defense", "Renewable Energy", "Fintech", "Healthcare", "Consumer Services"
    ]
    // Search if company_industry contains parts of momentum_industries
    let is_momentum = false
    momentum_industries.forEach(industry => {
      if(momentumData.companyIndustry.toLowerCase().includes(industry.toLowerCase())){
        is_momentum = true
      }
    })
  
    return {
      id: "map1",
      short_description: "Momentum industry",
      warnings: warnings,
      weight: is_momentum ? 0.3 : -0.3,
      weight_min_value: -0.3,
      weight_max_value: 0.3,
      data_info: undefined,
      result: is_momentum,
      value: ""
    }
}

export const computeMomentumPillar2 = (momentumData: MomentumDataForPillars): PillarData => {
    /**
     * Compute momentum pillars 2
     * Return true if the volume value is greater than the long term moving average
     */
    let warnings: string[] = []
    let score = 0
    let is_valid = false
    let value = ''
    const lastSmaLT = momentumData.volumeTechnicalData.smaLongTermData[momentumData.volumeTechnicalData.smaLongTermData.length-1]
    const lastVolume = momentumData.volumeTechnicalData.stockPrice[momentumData.volumeTechnicalData.stockPrice.length-1]
    if(lastVolume > lastSmaLT){
      score = 0.2
      is_valid = true
    } else {
      score = -0.2
      is_valid = false
    }
    return {
      id: "map2",
      short_description: "Volume trend analysis",
      warnings: warnings,
      weight: score,
      weight_min_value: -0.2,
      weight_max_value: 0.2,
      data_info: undefined,
      result: is_valid,
      value: value
    }
}

export const computeMomentumPillar3 = (momentumData: MomentumDataForPillars): PillarData => {
    /**
     * Compute momentum pillars 3
     * Return true if the short term average volume is trending up, false otherwise
     */
    let warnings: string[] = []
    let score = 0
    let is_valid = false
    let value = ''
    const recentSelection = momentumData.volumeTechnicalData.smaShortTermData.slice(momentumData.volumeTechnicalData.smaShortTermData.length-10, momentumData.volumeTechnicalData.smaShortTermData.length-1)
    const recentSelectionAverage = recentSelection.reduce((a, b) => a + b, 0) / recentSelection.length
    const [st_growth, b] = leastSquares1d(arrayRange(0, recentSelection.length, 1), recentSelection)
    const percentageGrowth = st_growth/recentSelectionAverage

    if(percentageGrowth > 0.01){
      score = 0.5
      is_valid = true
    } else if(percentageGrowth < -0.01) {
      score = -0.5
      is_valid = false
    } else {
      score = 0.0
      is_valid = false
    }
    return {
      id: "map3",
      short_description: "Short Volume trend analysis",
      warnings: warnings,
      weight: score,
      weight_min_value: -0.5,
      weight_max_value: 0.5,
      data_info: undefined,
      result: is_valid,
      value: value
    }
}

export const computeAllMomentumPillars = (momentumData: MomentumDataForPillars): Array<PillarData> => {
    const res: Array<PillarData> = []
    for (const pillar of getTechnicalPillarsFunctions()) {
        res.push(pillar(momentumData.priceTechnicalData))
    }
    res.push(computeMomentumPillar1(momentumData))
    res.push(computeMomentumPillar2(momentumData))
    res.push(computeMomentumPillar3(momentumData))
    return res
}


/**
 * Helpers functions starting here
 */


export const computePriceToVolumeRatio = (priceData: Array<number>, volumeData: Array<number>): Array<number> => {
  const priceToVolumeRatio: Array<number> = [];
  if (priceData.length !== volumeData.length) {
    console.error('Price and volume data must have the same length.');
  }

  for (let i = 0; i < priceData.length; i++) {
    const price = priceData[i];
    const volume = volumeData[i];

    // Check to avoid division by zero
    if (volume === 0) {
        priceToVolumeRatio.push(0); // Push 0 or another appropriate value if volume is zero
    } else {
        priceToVolumeRatio.push(price / volume); // Calculate price-to-volume ratio
    }
  }
  return priceToVolumeRatio;
}
